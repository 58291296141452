/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "../../assets/stylesheets/tailwind.scss";

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)

var moment = require('moment');
$.ajaxSetup({
  headers: { "Time-Zone-Offset-Hours": moment().utcOffset() / 60 }
});

if (window.Sentry) {
  Sentry.onLoad(function() {
    Sentry.init({
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions
      tracePropagationTargets: [/\/l\/\w+-\w+$/, /play/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  });

  $(document)
    .ajaxError(
      function(event, jqxhr, settings, exception) {
        const errorMessage = `JS: AJAX Error: ${settings.type}: ${settings.url}`;
  
        Sentry.captureException(
          new Error(errorMessage),
          {
            extras: {
              data: settings.data,
              exception: exception,
              responseText: jqxhr.responseText,
              status: jqxhr.status,
              statusText: jqxhr.statusText,
              type: settings.type,
              url: settings.url,
            },
            tags: {
              ajax: "true",
            },
          }
        );
      }
    );
}

